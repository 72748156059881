import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';

import { catchError, map, switchMap, filter, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';


@Injectable()
export class AuthService {

	private refreshTokenInProgress = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private http: HttpClient) { }
	// Authentication/Authorization
	


  renewToken(url: string): Observable<boolean> {
    let user = {
      accessToken :  '',
      refreshToken: ''
    }
		let token = localStorage.getItem(environment.authTokenKey);
		let renewtoken = localStorage.getItem(environment.refreshToken);

		localStorage.setItem(environment.authTokenKey, user.accessToken);
		localStorage.setItem(environment.refreshToken, user.refreshToken);
		this.refreshTokenSubject.next(user.accessToken);
		this.refreshTokenInProgress = false;
		return of(true);
						
	}

	
}
