import { Component, OnInit } from '@angular/core';
import { PaymentPlanService } from '../../services/paymentPlan.service';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'paymentplans',
  templateUrl: './paymentplans.component.html',
})
export class AdminPaymentPlansComponent {
  public response;
  public paymentPlans;
  public membershipPlans;
  plansForm: FormGroup;
  membershipForm: FormGroup;
  public planid;
  public editplan = false;
  public addPlan = false;
  public addMembershipPlan = true;
  constructor(private paymentPlanService: PaymentPlanService, private fb: FormBuilder) {
  }
  ngOnInit() {
  
    this.showPlans();
    this.initForm();

  }


  initForm() {
    this.plansForm = this.fb.group({
      planTokens: ['', Validators.compose([
        Validators.required
      ]),
      ],
      planPrice: ['', Validators.compose([
        Validators.required
      ]),
      ],
    });
    this.membershipForm = this.fb.group({
      days: ['', Validators.compose([
        Validators.required
      ]),
      ],
      planPrice: ['', Validators.compose([
        Validators.required
      ]),
      ],
    });
  }
  showPlans() {
    this.paymentPlanService.getPlans()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;

            this.paymentPlans = this.response.result;
          }
        })
      )
      .subscribe();

    this.paymentPlanService.getMembershipPlans()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;

            this.membershipPlans = this.response.result;
          }
        })
      )
      .subscribe();
}
  EditPlan(id) {
    this.editplan = true;
    this.planid = id;


    this.paymentPlanService.getSinglePlan(id)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.plansForm.patchValue({
              planTokens: this.response.result.tokens,
              planPrice: this.response.result.price
            })

          }
        }),
        catchError(err => {


          if (err && err.status == 400) {

          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  DeletePlan(id) {
    this.planid = id;
    this.paymentPlanService.deletePlan(id)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.showPlans();
          }
        }),
        catchError(err => {
          if (err && err.status == 400) {

          }
          return of([]);
        }),
        finalize(() => {
        })
      )
      .subscribe();
  }
  AddPlan() {
    this.addPlan = true;
  }
  SubmitAdd() {

    const controls = this.plansForm.controls;
    if (this.plansForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }



    let plan = {
      tokens: controls['planTokens'].value,
      price: controls['planPrice'].value
    };


    this.paymentPlanService.savePlan(plan)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.addPlan = false;
            this.showPlans();
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {

          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  SubmitEdit(id) {

    const controls = this.plansForm.controls;
    if (this.plansForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }



    let plan = {
      id:id,
      tokens: controls['planTokens'].value,
      price: controls['planPrice'].value
    };


    this.paymentPlanService.editPlan(plan)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.editplan = false;
            this.showPlans();
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {

          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.plansForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }


  SubmitAddMembership() {

    const controls = this.membershipForm.controls;
    if (this.membershipForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }



    let plan = {
      days: controls['days'].value,
      price: controls['planPrice'].value
    };


    this.paymentPlanService.saveMembershipPlan(plan)
      .pipe(
        tap(res => {
          if (res) {
            
            this.showPlans();
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {

          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
}
