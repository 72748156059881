import { Component, OnInit, Pipe, PipeTransform} from '@angular/core';
import { PagesService } from '../services/pages.service';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';



@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Component({
  selector: 'app-legal1',
  templateUrl: './legal1.component.html',
})
export class LegalComponent {
  public background;
  public response;
  public page;
  public pageContent;
  public title;
  public id;
  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];
  constructor(
    private PagesService: PagesService,
    private route: ActivatedRoute,
    private sanitized: DomSanitizer
  ) {}
  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];

    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.initialiseState(); // reset and set based on new parameter this time
    });
 
    
       
  }
  initialiseState() {
  this.PagesService.getSinglePage(this.id)
  .pipe(
    tap(res => {
      if (res) {
        this.response = res;


        if (this.id == '19a39c89-ab81-4079-9b98-894cc05a188e') {
          this.title = 'Terms and conditions of use of FREEKICK.CO';
        }
        if (this.id == '2105906c-1db8-4b83-8522-56d561ca8187') {
          this.title = 'LEGALPAGES.TITLE2';
        }
    
        this.page = this.response.result;
        let language = localStorage.getItem("language");
        if (language == 'bg') {
          this.pageContent = this.page.contentBg
        } else {
          this.pageContent = this.page.contentEn
        }
      }
    })
  )
  .subscribe();
  }


}
