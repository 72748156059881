import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPayPalModule } from 'ngx-paypal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RegisterService } from './services/register.service';
import { ContactService } from './services/contact.service';
import { PagesService } from './services/pages.service';
import { PaymentPlanService } from './services/paymentPlan.service';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './search/search.component';
import { SafePipe } from './search/search.component';

import { ContactsComponent } from './contacts/contacts.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
//import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
//import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { ProfileComponent } from './profile/profile.component';
import { NgbDateCustomParserFormatter } from './profile/profile.component';



import { PlayerNavComponent } from './profile/playernavigation/playernav.component';
import { PhotoGalleryComponent } from './profile/photogallery/photogallery.component';
import { VideoGalleryComponent } from './profile/videogallery/videogallery.component';
import { PaymentPlansComponent } from './profile/paymentplans/paymentplans.component';
import { ScoutProfileComponent } from './profile/scout/scoutprofile.component';
import { LegalComponent } from './legalpage1/legal1.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotenPasswordComponent } from './authentication/forgottenpassword/forgottenpassword.component';
import { ResetPasswordComponent } from './authentication/resetpassword/resetpassword.component';
import { RegisterComponent } from './register/register.component';
import { EmailConfirmationComponent } from './authentication/emailconfigmation/emailconfigmation.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ChooseProfileComponent } from './profile/chooseProfileType/chooseprofile.component';
import { FooterComponent } from './footer/footer.component';
import { AdminComponent } from './admin/admin.component';
import { environment } from '../environments/environment';
import { TokenInterceptor } from './authentication/token-interceptor.service';
import { AuthService } from './authentication/auth.service';
import { VideoGalleryService } from './services/videoGallery.service';
import { PlayerProfileService } from './services/playerProfile.service';
import { SearchService } from './services/search.service';
import { ScoutsService } from './services/scouts.service';
//Admin
import { AdminPaymentPlansComponent } from './admin/paymentplans/paymentplans.component';
import { AdminLegalPagesComponent } from './admin/legalpages/legalpages.component';
import { AdminPendingScoutsComponent } from './admin/pendingScouts/pendingscouts.component';
import { AdminUsersComponent } from './admin/users/users.component';
// Store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers, reducers } from './config/reducers';

//social
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

//recaptcha
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms'
import { AngularEditorModule } from '@kolkov/angular-editor';


//Pipe
import { SafeHtmlPipe } from './legalpage1/legal1.component';



//material
import { NgbModule, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';



let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.google.ClientId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.facebook.ClientId)
  }
]);

export function provideConfig() {
  return config;
}




@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    SignupComponent,
    ProfileComponent,
    RegisterComponent,
    ContactsComponent,
    EmailConfirmationComponent,
    LanguageSelectorComponent,
    ForgotenPasswordComponent,
    ResetPasswordComponent,
    FooterComponent,
    SearchComponent,
    AdminComponent,
    AdminPaymentPlansComponent,
    AdminLegalPagesComponent,
    AdminPendingScoutsComponent,
    ChooseProfileComponent,
    PlayerNavComponent,
    PhotoGalleryComponent,
    VideoGalleryComponent,
    PaymentPlansComponent,
    ScoutProfileComponent,
    AdminUsersComponent,
    LegalComponent,
    SafeHtmlPipe,
    SafePipe

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    NgbModule,
    //ApiAuthorizationModule,
    SocialLoginModule,
    NgxPayPalModule,
    AngularEditorModule,
    TranslateModule.forRoot(),
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'counter', component: CounterComponent },
      { path: 'signup', component: SignupComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'profile', component: ProfileComponent },
      { path: 'scoutprofile', component: ScoutProfileComponent },
      { path: 'profile/photogallery', component: PhotoGalleryComponent },
      { path: 'profile/videogallery', component: VideoGalleryComponent },
      { path: 'profile/paymentplans', component: PaymentPlansComponent},
      { path: 'profiletype', component: ChooseProfileComponent },
      { path: 'admin', component: AdminComponent },
      { path: 'contactus', component: ContactsComponent },
      { path: 'Identity/Account/ConfirmEmail', component: EmailConfirmationComponent },
      { path: 'Identity/Account/ResetPassword', component: ResetPasswordComponent },
      { path: 'forgotenpassword', component: ForgotenPasswordComponent },
      { path: 'legalpage/:id', component: LegalComponent },
      { path: 'viewprofile/:id', component: ProfileComponent },
      { path: 'search', component: SearchComponent },
   
    ]),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    StoreDevtoolsModule.instrument(),
  ],
  exports: [
    LanguageSelectorComponent
  ],
  providers: [
    RegisterService,
    ContactService,
    PaymentPlanService,
    AuthService,
    TokenInterceptor,
    VideoGalleryService,
    PlayerProfileService,
    PagesService,
    SearchService,
    ScoutsService,
    SafeHtmlPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
