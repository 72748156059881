import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class ContactService {

  constructor(private http: HttpClient) { }
 
  public sendEmail(message) {
    const response = this.http.post(environment.apiUrl + 'api/Contact/sendContact', { email: message.Email, name: message.Name, description: message.Description, recaptureToken: message.recaptureToken });
    return response;
  }
 
}
