// BG
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Choose language',
    },
    foot1: "left",
    foot2: "right",
    foot3: 'both',
    MENU: {
      HOME: "Home",
      SIGNUP: "Login",
      PROFILE: "Profile",
      ENTER: "Enter",
      REGISTER: "Registration",
      CONTACTS: "Contact us",
      LOGOUT: "Log Out",
      SEARCH: "Search"

    },
    REGISTRATION: {
      REGISTER: "Registration",
      MESSAGES: {
        CONFIRMATIONEMAILSENT: "Email confirmation has being sent to your email",
        UNABLETOLOADUSER: "Unable to load user"

      },
      ERRORS: {
        REQUIRED: "This field is required",
        NOTVALIDEMAIL: "This email is invalid",
        EMAILMINLENGTH: "Maximum length is 320 characters",
        PASSWORDMIN: "Password must be atleast 8 symbols",
        MISMATCH: "Passwords don't match",
        USEREXISTS: "This user already exist, try to login or use forgotten password form"
      }

    },
    SIGNUP: {
      EMAIL: "Email",
      PASSWORD: "Password",
      CONFIRMPASSWORD: "Confirm password",
      FORGOTPASSWORD: "Forgotten password",
      MESSAGES: {
        INVALIDUSERNAMEORPASSWORD: "Invalid user or password",
        EMAILNOTCONFIRMED: "This email is not confirmed",
        ACCOUNTNOTCONFIRMEDORREGISTRED: "Email is not registred or confirmed",
        SIGNOUT: "User is loged out"
      },
      ERRORS: {
        GENERAL: "Request not complete please verify your inputs"
      }
    },
    PAYMENTPLAN: {
      NAME: "Plan",
      BUY: "Buy",
      TOKENS: "Tokens",
      PRICE: "Price",
      PRICESIGN: "$"

    },
    PROFILE: {
      TYPE1: {
        TITLE: "Player / Parent",
        DESCRIPTION: "Short description of the plan"
      },
      TYPE2: {
        TITLE: "Scout",
        DESCRIPTION: "Short description of the plan"
      },
      ACTION: {
        CHOOSEPROFILE: "Confirm",
        AREYOUSURE: "Are you sure you want to be a Player/Parent profile?",
        AREYOUSURESCOUT: "Are you sure you want to be a Scout profile?"
      },

      FORMS: {
        NAME: "First Name",
        LASTNAME: "Last Name",
        CITY: "City",
        COUNTRY: "Country",
        TITLE: "Profile Settings",
        DOB: "Date Of Birth",
        NATIONALITY: "Nationality",
        COUNTRYOFRESIDENCE:"Country of residence",
        HEIGHT: "Height in cm ",
        WEIGHT: "Weight in kg",
        SCOUTAPPROVAL: "Scout",
        VIDEOTITLE: "Video Gallery",
        VIDEOTITLEADD: "Add video to gallery",
        VIDEOCATEGORY1: "Match Videos",
        VIDEOCATEGORY2: "Training Videos",
        VIDEOCATEGORY3: "Freestyle Videos",
        VIDEOCATEGORY4: "Add Video",
        VIDEONAME: "Video Name",
        PHOTOADD: "Add Photos to your gallery",
        PHOTO: "Choose image",
        PHOTONAME: "Title",
        TOKENS: "Tokens",
        PRICE: "Price",
        URL: "Video youtube link",
        SUBMIT: "SUBMIT",
        ADDRESS: "Address",
        DESCRIPTION: "Short bio",
        TEAM: "Team/Academy",
        TEAMACADEMY: "If you represent a team or an academy",
        TEAMLOGO: "Team logo",
        DOCUMENT: "Attach Document",
        NICKNAME: "Nickname",
        RATING: "Rating",
        RATEPLAYER: "Rate player",
        MENTAL: "Mental",
        PHYSICAL: "Physical",
        TECHNICAL: "Technical",
        REMOVEFROMFAVORITES: "Remove to favorites",
        ADDTOFAVORITES:"Add to favorites",
        PLAYERFOLLOWED: "Player is already in your follow list",
        CM: "cm",
        KG:"kg",
        POSITIONS: {
          TITLE:"Position",
          POSITION1: "Goalkeeper",
          POSITION2: "Defender",
          POSITION3: "Midfielder",
          POSITION4: "Forward",
        },
        PREFEREDFOOT: {
          TITLE: "Prefered foot",
          FOOT1: "Left",
          FOOT2: "Right",
          FOOT3:"Both"
        },
        DAYS: "Period"
      

      }

    },
    EMAILCONFIRMATION: {
      TITLE: "Email confirmation",
      MESSAGES: {
        EMAILCONFIRMED: "Email is confirmed",
        EMAILNOTCONFIRMED: "Email is not confirmed"
      }
    },
    STATUSMESSAGE: {
      EMAIL_CONFIRMED: "Email is confirmed",
      NOSUCHEMAIL: "This email is not registred"
    },
    FORGOTENPASSWORD: {
      TITLE: "Forgotten password",
      ACTION: "Confirm",
      MESSAGES: {
        EMAILSENT: "Email confirmation has being sent"
      }
    },
    RESETPASSWORD: {
      TITLE: "Reset password",
      ACTION: "Confirm",
      ERRORS: {
        INVALIDTOKEN: "Invalid token",
        INVALIDREFRESHTOKEN: "Invalid refresh token",
      },
      MESSAGES: {
        PASSWORDRESET: "Password has being changed"
      }
    },
    CONTACTS: {
      SENDBUTTON: "Send",
      MESSAGELABEL: "Message",
      EMAILLABEL: "Email",
      NAMELABEL: "Name",
      ERRORS: {
        GENERAL: "Something went wrong, please try again",
        SUBMITSUCCESS:"Data Updated Successfully",
        REQUIRED: "This filed is required",
        BADTOKEN: "Wrong code"
      },
      SUCCESS: "Message has being sent, we will reach back to you as soon as posible."

    },
    HOMEPAGE: {
      MARKETINGMESSAGE: {
        TITLE: "Welcome to Freekick.cc",
        MESSAGE: "Sport is often defined as an athletic activity that involves competition and a regular training in a team or individually. But we all know that sport and, notably football, is way more than that. It is a dream that could become true, a talent that could bring you to stardom, a purpose of being, a choice and a concept of living. However, it all starts with an opportunity that one has been given! "
      },
      MARKETINGMESSAGE2: {
        TITLE: "FREEKICK- The Home for Football Stars",
        MESSAGE: "Short description of the features described below on the paltform advantages"
      },
      MARKETINGMESSAGE3: {
        TITLE: " How Freekick works",
        MESSAGE: " Register an account with your profile and start uploading your videos, with your mobile phone or computer, showing your technical and physical skills and performance during a game. Scouts from all over the world will be able to rate you in three categories that will feed into an overall rating, which will be visible to all scouts and players.  Scouts will be able to search with preferred filters for any kind of player to review, give ratings and contact potential signings. If a scout likes your presentation, they will be able to contact you directly to offer a trail or a place in their academy. We can do this together, let’s try! "
      },
      SCOUTFROM: "Scout from",
      RATEDPLAYER: "rated player",
      FROM: "from",
      WITHRATING:"with rating",
      PROMOSECTION: {
        PROMO1: {
          TITLE: "About us",
          MESSAGE: "Freekick is a platform that has been created by professional players, coaches, arbiters, and medical staff to offer the opportunity to every child in the world to show its talent and willingness to pursue a professional football career. Freekick is a social network connecting young football players with professional scouts.It is an opportunity for kids and adolescents from all over the world to be seen by professionals from star academies and acclaimed teams."
        },
        PROMO2: {
          TITLE: "Concept",
          MESSAGE: "Freekick is a safe space to show your competencies, ambition and achieve perfection, by receiving feedback from professionals on your performance. It is a virtual playground to compare with other players in your category across the world and exchange tips on acquiring skills, training, and playing strategies."
        },
        PROMO3: {
          TITLE: "For players",
          MESSAGE: "Freekick is easy and accessible to everyone. It takes a few clicks to register that will push you to build a solid profile to kick-start your professional experience. You will be able to upload regularly short videos with your best performances to create a track record from very early age showing your skills, abilities, and determination while training, exercising, or playing a full-sided game. As your profile and performances will be visible to professional assessors all over the world – scouts, coaches, current and ex-players, you will be able to receive regular and differentiated feedback from them and be noticed and contacted by them – you will be creating your own opportunities. It will also allow you to measure yourself to others and see your strengths and own evolution over time, along with the areas where you need improvement. "
        },
        PROMO4: {
          TITLE: "For scouts",
          MESSAGE: "Freekick is also a virtual meeting place where skills and demand could match, while respecting privacy, data protection and children’s rights. Scouts could discover and track wonderkid potential without intermediaries and fees, using different tools to filter and compare. The only investment required is their valuable feedback."
        },
        RATINGS: {
          TITLE:"Latest Ratings",
          MESSAGE: "Players rated so far"
        }

      }
    }
    ,
    NOTIFICATIONS: {
      NONOTIFICATIONS:"No Notifications"
    },
    APROVAL: {
      PENDING:"Your application is in a process of aproval",
    },
    LEGALPAGES: {
      TITLE1:"Terms and conditions",
      TITLE2:"Privacy policy",
      TITLE3: "Title 3",
      TRANSLATIONEN: "English version",
      TRANSLATIONBG: "Bulgarian version"
    }

  }
};
