// BG
export const locale = {
  lang: 'bg',
  data: {
    TRANSLATOR: {
      SELECT: 'Избери език',
    },
    foot1: "ляв",
    foot2: "десен",
    foot3: 'двата',
    MENU: {
      HOME: "Начало",
      SIGNUP: "Вход",
      PROFILE: "Профил",
      ENTER: "Изпрати",
      REGISTER: "Регистрация",
      CONTACTS: "Свържи се с нас",
      LOGOUT: "Изход",
      SEARCH: "Търсене"

    },
    REGISTRATION: {
      REGISTER: "Регистрация",
      MESSAGES: {
        CONFIRMATIONEMAILSENT: "Емейл за потвърждения беше изпратен",
        UNABLETOLOADUSER: "Грешка при обработката на потребител"

      },
      ERRORS: {
        REQUIRED: "Това поле е задължително",
        NOTVALIDEMAIL: "Този Емейл е невалиден",
        EMAILMINLENGTH: "Максимална дължина 320 символа",
        PASSWORDMIN: "Паролата трябва да е минимум 8 симвула",
        MISMATCH: "Паролите не съвпадат",
        USEREXISTS: "Такъв потребилтел вече съществува"
      }

    },
    SIGNUP: {
      EMAIL: "Емейл",
      PASSWORD: "ПАрола",
      CONFIRMPASSWORD: "Потвърди паролата",
      FORGOTPASSWORD: "Забравена парола",
      MESSAGES: {
        INVALIDUSERNAMEORPASSWORD: "Невалиден потребител или парола",
        EMAILNOTCONFIRMED: "Този Емейл не е потвърден",
        ACCOUNTNOTCONFIRMEDORREGISTRED: "Емейла не е регистриран или потвърден",
        SIGNOUT: "Потребителя не е логнат"
      },
      ERRORS: {
        GENERAL: "Грешка в заявката"
      }
    },
    PAYMENTPLAN: {
      NAME: "План",
      BUY: "Купи",
      TOKENS: "Токени",
      PRICE: "Цена",
      PRICESIGN: "$"

    },
    PROFILE: {
      TYPE1: {
        TITLE: "Player / Parent",
        DESCRIPTION: "Short description of the plan"
      },
      TYPE2: {
        TITLE: "Scout",
        DESCRIPTION: "Short description of the plan"
      },
      ACTION: {
        CHOOSEPROFILE: "Choose profile type",
        AREYOUSURE: "Are you sure you want to be a Player/Parent profile, description follows....",
        AREYOUSURESCOUT: "Are you sure you want to be a Scout profile, description follows...."
      },

      FORMS: {
        NAME: "Име",
        LASTNAME: "Фамилия",
        CITY: "Град",
        COUNTRY: "Държава",
        TITLE: "Настройки на профиила",
        DOB: "Дата на раждане",
        NATIONALITY: "Националност",
        COUNTRYOFRESIDENCE:"Държава на пребиваване",
        HEIGHT: "Височина",
        WEIGHT: "Тегло",
        SCOUTAPPROVAL: "Скаут",
        VIDEOTITLE: "Видео галерия",
        VIDEOTITLEADD: "Добави видео към галерията си",
        VIDEOCATEGORY1: "Видео на мач",
        VIDEOCATEGORY2: "Видео от тренировки",
        VIDEOCATEGORY3: "Видео в свободен стил",
        VIDEOCATEGORY4: "Добави видео",
        VIDEONAME: "Име на видеото",
        PHOTOADD: "Добави снимки към галерията",
        PHOTO: "Избери картинка",
        PHOTONAME: "Заглавие",
        TOKENS: "Токен",
        PRICE: "Цена",
        URL: "Видео връзка към youtube",
        SUBMIT: "Запази",
        ADDRESS: "Адрес",
        DESCRIPTION: "Кратка биография",
        TEAM: "Кой отбор представляваш",
        TEAMLOGO: "Лого",
        DOCUMENT: "Добави документ",
        NICKNAME: "Пряков",
        RATING: "Рейтинг",
        RATEPLAYER: "Оцени играч",
        MENTAL: "Умственно",
        PHYSICAL: "Физически",
        TECHNICAL: "Технически",
        REMOVEFROMFAVORITES: "Премахни от любими",
        ADDTOFAVORITES:"Добави в любими",
        PLAYERFOLLOWED: "Играчът е в листата ви с последователи",
        CM: "см",
        KG:"кг",
        POSITIONS: {
          TITLE:"Позиция",
          POSITION1: "Вратар",
          POSITION2: "Защитник",
          POSITION3: "Халф",
          POSITION4: "Нападател",
        },
        PREFEREDFOOT: {
          TITLE: "Предпочитан крак",
          FOOT1: "Ляв",
          FOOT2: "Десен",
          FOOT3:"Двата"
        },
        DAYS: "Период"
      

      }

    },
    EMAILCONFIRMATION: {
      TITLE: "Потвърждение на Емейл",
      MESSAGES: {
        EMAILCONFIRMED: "Емейла е потвърден",
        EMAILNOTCONFIRMED: "Емейла не е потвърден"
      }
    },
    STATUSMESSAGE: {
      EMAIL_CONFIRMED: "Емейла е потвърден",
      NOSUCHEMAIL: "Този емейл не е регистриран"
    },
    FORGOTENPASSWORD: {
      TITLE: "Забравена парола",
      ACTION: "Потвърди",
      MESSAGES: {
        EMAILSENT: "Емейл с потвърждение беше изпратен "
      }
    },
    RESETPASSWORD: {
      TITLE: "Промени паролата",
      ACTION: "Потвърди",
      ERRORS: {
        INVALIDTOKEN: "Невалиден токен",
        INVALIDREFRESHTOKEN: "Невалиден токен за идентификация",
      },
      MESSAGES: {
        PASSWORDRESET: "Паролата беше сменена"
      }
    },
    CONTACTS: {
      SENDBUTTON: "Изпрати",
      MESSAGELABEL: "Съобщение",
      EMAILLABEL: "Емейл",
      NAMELABEL: "Име",
      ERRORS: {
        GENERAL: "Грешка, опитайте отново",
        SUBMITSUCCESS:"Данните получени",
        REQUIRED: "Това поле е задължително",
        BADTOKEN: "Грешен код"
      },
      SUCCESS: "Message has being sent, we will reach back to you as soon as posible."

    },
    HOMEPAGE: {
      MARKETINGMESSAGE: {
        TITLE: "Welcome to Freekick.cc",
        MESSAGE: "Спортът често се определя като спортна дейност, която включва състезание и редовна тренировка в екип или индивидуално. Но всички знаем, че спортът и по-специално футболът са много повече от това. Това е мечта, която може да се сбъдне, талант, който би могъл да ви доведе до слава, цел на съществуване, избор и концепция за живот. Всичко обаче започва с дадена възможност! "
      },
      MARKETINGMESSAGE2: {
        TITLE: "FREEKICK- ДОМЪТ НА ФУТБОЛНИТЕ ЗВЕЗДИ",
        MESSAGE: "Short description of the features described below on the paltform advantages"
      },
      MARKETINGMESSAGE3: {
        TITLE: " Как работи Freekick",
        MESSAGE: "  Регистрирайте акаунт с вашия профил и започнете да качвате видеоклиповете си с мобилния си телефон или компютър, показвайки вашите технически и физически умения, както и представяне по време на игра. Скаутите от цял ​​свят ще могат да ви оценят в три категории, които ще се съберат в обща оценка, която ще бъде видима за всички скаути и играчи. Скаутите ще могат да търсят с предпочитани филтри за всякакъв вид играчи, които да преглеждат, да дават оценки и да се свързват с потенциални попълнения. Ако скаут харесва вашата презентация, те ще могат да се свържат директно с вас, за да предложат място в тяхната академия или път за развитие. Можем да направим това заедно, нека опитаме! "
      },
      SCOUTFROM: "Скаут от",
      RATEDPLAYER: "оцени играч",
      FROM: "от",
      WITHRATING:"с рейтинг",
      PROMOSECTION: {
        PROMO1: {
          TITLE: "За нас",
          MESSAGE: "Freekick е платформа, създадена от професионални играчи, треньори, арбитри и медицински персонал, за да предложи възможност на всяко дете по света да покаже своя талант и желание да преследва професионална футболна кариера. Freekick е социална мрежа, свързваща млади футболисти с професионални скаути. Това е възможност за деца и юноши от цял ​​свят да бъдат видени от професионалисти от звездни академии и известни отбори. "
        },
        PROMO2: {
          TITLE: "Концепция",
          MESSAGE: "Freekick е безопасно място за показване на вашите умения, амбиции и постигане на съвършенство, чрез получаване на обратна връзка от професионалисти за вашето представяне.Това е виртуална площадка за сравнение с други играчи от вашата категория по света и обмен на съвети за придобиване на умения, обучение и стратегии за игра."
        },
        PROMO3: {
          TITLE: "За играчи",
          MESSAGE: "Freekick е лесен и достъпен за всички. Регистрацията отнема няколко кликвания, което ще ви помогне да изградите солиден профил, за да стартирате професионалния си опит. Ще можете да качвате редовно кратки видеоклипове с най-добрите си изпълнения, за да изградите вашата футболна история от много ранна възраст, показваща вашите умения, способности и решителност, докато тренирате, упражнявате се или играете двустранен мач. Тъй като вашият профил и изпълнения ще бъдат видими за професионални оценители по целия свят-скаути, треньори, настоящи и бивши играчи, ще можете да получавате редовни и диференцирани отзиви от тях и да бъдете забелязвани и контактувани от тях- вие ще създавате вашите собствени възможности. Също така ще ви позволи да се измервате спрямо другите и да видите силните си страни и собствената си еволюция във времето, заедно с областите, в които имате нужда от подобрение."
        },
        PROMO4: {
          TITLE: "За Скаути",
          MESSAGE: "Freekick също е виртуално място за срещи, където уменията и търсенето биха могли да съвпаднат, като същевременно се зачитат поверителността, защитата на данните и правата на децата. Скаутите биха могли да открият и проследят потенциала на следващия носител на златната топка, без посредници и такси, като използват различни инструменти за филтриране и сравняване. Единствената необходима инвестиция е тяхната ценна обратна връзка."
        },
        RATINGS: {
          TITLE:"Последни оценявания",
          MESSAGE: "Играчи оцененни досега"
        }

      }
    }
    ,
    NOTIFICATIONS: {
      NONOTIFICATIONS:"Нямате нотификации"
    },
    APROVAL: {
      PENDING:"Вашата аплиакция се разглежда в момента",
    },
    LEGALPAGES: {
      TITLE1:"Политика за ползване",
      TITLE2:"Политика за лични данни",
      TITLE3: "Title 3",
      TRANSLATIONEN: "English version",
      TRANSLATIONBG: "Bulgarian version"
    }

  }
};
