import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable()
export class PagesService {

  constructor(private http: HttpClient) { }
 
  public  getPage() {
    const response = this.http.get(environment.apiUrl +'api/PageContent');
    return response;
  }

  public getSinglePage(id) {
    const response = this.http.get(environment.apiUrl +'api/PageContent/'+id);
    return response;
  }


  public savePage(id) {
    const response = this.http.put(environment.apiUrl +'api/PageContent/' + id.id, { contentBg: id.contentBg, contentEn: id.contentEn});
    return response;
  }

  public getAllRatings(count) {
    const response = this.http.get(environment.apiUrl + 'api/PageContent/lastRatings/' + count);
    return response;
    
  }
}
