import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../services/register.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { ConfirmPasswordValidator } from '../../register/confirm-password.validator';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})

export class ResetPasswordComponent  implements OnInit {
  signinForm: FormGroup;
  public response;
  public background;
  public generalError;
  public generalErrorMessage;
  public generalSuccess;
  public generalSuccessMessage;
  public hasValidUrl = true;
  public shouldShowLogin = false;

  private userId;
  private code;


  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'
  ];


  constructor(private RegisterService: RegisterService,
    private router: Router,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
    this.initForm();

    let url_string = window.location.href;
    let url = new URL(url_string);
    this.userId = url.searchParams.get("userId");
    this.code = url.searchParams.get("code");

    if (!this.userId || !this.code) {
      this.hasValidUrl = false;
      this.generalErrorMessage = 'RESETPASSWORD.ERRORS.INVALIDTOKEN';
    }
  }

  initForm() {
    this.signinForm = this.fb.group({
      Password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])
      ],
      ConfirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])
      ]
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword
    });
  }
  /**
 * Form Submit
 */

  Submit() {
    const controls = this.signinForm.controls;
    if (this.signinForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }


    let _resetPassword = {
      Password: controls['Password'].value,
      ConfirmPassword: controls['ConfirmPassword'].value,
      userId: this.userId,
      code: this.code
    } 


    this.RegisterService.resetPassword(_resetPassword)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.generalSuccess = true;
            this.generalSuccessMessage = this.response.message;
            this.shouldShowLogin = true;
          }
        }),
        catchError(err => {
          
          this.generalError = true;
          this.generalErrorMessage = 'SIGNUP.ERRORS.GENERAL';
          if (err && err.status == 400) {
            if (err.error.ConfirmPassword) {
              this.generalErrorMessage = err.error.ConfirmPassword[0];
            } else if (err.error.Password) {
              this.generalErrorMessage = err.error.Password[0];
            }else {
              this.generalErrorMessage = err.error.errorsAsString;
            }
          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();

  }

  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.signinForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
