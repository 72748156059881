import { Component, OnInit, ViewChild } from '@angular/core';
import { RegisterService } from '../../services/register.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { PaymentPlanService } from '../../services/paymentPlan.service';

import {
  IPayPalConfig,
  ICreateOrderRequest 
} from 'ngx-paypal';

@Component({
  selector: 'paymentplansplayer',
  templateUrl: './paymentplans.component.html',
  styleUrls: ['./paymentplans.component.scss']
})

export class PaymentPlansComponent implements OnInit {
  public response;
  public paymentPlans;
  public payPalConfig?: IPayPalConfig;
  public transactionId;
  public showOnePlan;

  public singlePlanTokens;
  public singlePlanPrice;
  public currentPlanID;

  public data: any;
 
  constructor(
    private paymentPlanService: PaymentPlanService
  ) { }
  ngOnInit() {

    this.paymentPlanService.getPlans()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.paymentPlans = this.response.result;
          }
        })
      )
      .subscribe();
    this.initConfig();


  }
  viewPlan(id, price, tokens) {

    this.showOnePlan = true;
    //this.transactionId = id;
    this.currentPlanID = id;
    this.singlePlanTokens = tokens;
    this.singlePlanPrice = price;

    this.initConfig();

  }

  private initConfig(): void {
    this.payPalConfig = {
      clientId: environment.paypal.ClientId,
      currency: "EUR",
      // for creating orders (transactions) on server see
      // https://developer.paypal.com/docs/checkout/reference/server-integration/set-up-transaction/
      createOrderOnServer:()=> this.paymentPlanService.createPaymentOrder(this.currentPlanID).then(data => {
        this.data = data;
        return this.data.result.id;
      }),
      onApprove: (data, actions) => {
        console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then(details => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });

      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.paymentPlanService.capturePayment(this.data.result.id).pipe(
         tap(res => {
           if (res) {
             this.response = res;

             window.location.reload();
   
           }
         })).subscribe();
        //this.showSuccess = true;
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        //this.showCancel = true;

      },
      onError: err => {
        console.log('OnError', err);
        //this.showError = true;
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        //this.resetStatus();
      },
    };
      }
 }


  


 

