import { Component, OnInit } from '@angular/core';
import { VideoGalleryService } from '../../services/videoGallery.service';
import { PlayerProfileService } from '../../services/playerProfile.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'photogallery',
    templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.scss']
})

export class PhotoGalleryComponent implements OnInit {
  public background;
  public image;
  profileForm: FormGroup;
  public currentCategory = 4;
  public activeDirectoryClass = 'category-4';
  public generalSuccess;
  public generalError;
  public generalSuccessMessage;
  public generalErrorMessage;
  public gallery;
  public response;
  public newProfileimage;


  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];


  constructor(private fb: FormBuilder, private VideoGalleryService: VideoGalleryService, private PlayerProfileService: PlayerProfileService) { }

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
    this.loadPhotos();
    this.initForm();
  }
  loadPhotos() {
    this.PlayerProfileService.getMyProfile()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.gallery = this.response.result.images;
          }
        })).subscribe();


  }
  initForm() {
    this.profileForm = this.fb.group({
      Title: ['', Validators.compose([
        Validators.required
      ]),
      ],
      Content: ['', Validators.compose([
        Validators.required
      ]),
      ],
     });
  }


  /**
 * Form Submit
 */
  Submit() {

    const controls = this.profileForm.controls;


    if (this.profileForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }


 



    let photo = {
      title: controls['Title'].value,
      content: this.image,

    };


    this.VideoGalleryService.addPhoto(photo)
      .pipe(
        tap(res => {
          if (res) {
            this.generalSuccess = true;
            this.generalError = false;
            this.generalSuccessMessage = 'CONTACTS.ERRORS.SUBMITSUCCESS';
            this.loadPhotos();
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {
            this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
            this.generalSuccess = false;
            this.generalError = true;
          }

          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  handleUpload(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.image = reader.result;
      this.image = this.image.replace('data:image/jpeg;base64,', '');
      this.image = this.image.replace('application/msword,', '');
      this.image = this.image.replace('application/vnd.openxmlformats-officedocument.wordprocessingml.document,', '');
      this.image = this.image.replace('image/jpg,', '');
      this.image = this.image.replace('application/pdf,', '');
      this.image = this.image.replace('image/png,', '');
      this.image = this.image.replace('application/vnd.ms-excel,', '');
      this.image = this.image.replace('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,', '');

    };
  }

  deletePhoto(id) {
    this.VideoGalleryService.deletePhoto(id).pipe(
      tap(res => {
        if (res) {
          this.loadPhotos();
        }
      }),
      catchError(err => {


        if (err && err.status == 400) {
          this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
          this.generalSuccess = false;
          this.generalError = true;
        }

        return of([]);
      }),
      finalize(() => {


      })
    )
      .subscribe();
  }
  setDefault(id) {
    this.VideoGalleryService.setProfileImage(id).pipe(
      tap(res => {
        if (res) {
          this.response = res;

          this.newProfileimage = this.response.result.url;
          
          this.loadPhotos();
          window.location.reload();
        }
      }),
      catchError(err => {
        if (err && err.status == 400) {
          this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
          this.generalSuccess = false;
          this.generalError = true;
        }

        return of([]);
      }),
      finalize(() => {


      })
    )
      .subscribe();
  }




  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.profileForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
 
}
