import { Component, OnInit} from '@angular/core';
import { locale as enLang } from './config/translations/en';
import { locale as bgLang } from './config/translations/bg';
import { TranslationService } from './services/translation.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'app';
  location: Location;
  constructor(private translationService: TranslationService) {

    // register translations
    this.translationService.loadTranslations(enLang, bgLang);
  }

  ngOnInit() {
    
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
    
  }
}
