import { Component, OnInit } from '@angular/core';
import { VideoGalleryService } from '../../services/videoGallery.service';
import { PlayerProfileService } from '../../services/playerProfile.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  SafeResourceUrl,
  DomSanitizer
} from '@angular/platform-browser';
@Component({
  selector: 'videogallery',
  templateUrl: './videogallery.component.html',
  styleUrls: ['./videogallery.component.scss']
})

export class VideoGalleryComponent implements OnInit {
  profileForm: FormGroup;
  public currentCategory = 4;
  public activeDirectoryClass = 'category-4';
  public generalSuccess;
  public generalError;
  public generalSuccessMessage;
  public generalErrorMessage;


  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];

  public background;
  public response;
  public videoResponse= [];

  public videoResponse2 = [];
  public videoResponse3 = [];

  constructor(private fb: FormBuilder, private sanitizer: DomSanitizer, private VideoGalleryService: VideoGalleryService, private PlayerProfileService: PlayerProfileService) { }

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];

    this.initForm();
    this.loadVideos();
  }
  loadVideos() {
    this.videoResponse = [];

  this.videoResponse2 = [];
  this.videoResponse3 = [];
    this.PlayerProfileService.getMyProfile()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;


            for (let video of this.response.result.videos) {
              if (!video) {
                return
              }

              console.log(video);
              if (video.category == '1') {
              
                this.videoResponse.push(video);
              }

              if (video.category == '2') {
                
                 this.videoResponse2.push(video);
              }

              if (video.category == '3') {
               
                this.videoResponse3.push(video);
              }
            }


            //console.log(this.videoResponse);
            //console.log(this.videoResponse2);
            //console.log(this.videoResponse3);
          }
        })).subscribe();


  }
  initForm() {
    this.profileForm = this.fb.group({
      Title: ['', Validators.compose([
        Validators.required
      ]),
      ],
      Url: ['', Validators.compose([
        Validators.required
      ]),
      ],
      Category: ['', Validators.compose([
        Validators.required
      ]),
      ]
    });
  }


  /**
 * Form Submit
 */
  Submit() {

    const controls = this.profileForm.controls;


    if (this.profileForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }



    let video = {
      title: controls['Title'].value,
      url: this.youtubeUrl(controls['Url'].value),
      category: controls['Category'].value,
      
    };


    this.VideoGalleryService.addVideos(video)
      .pipe(
        tap(res => {
          if (res) {
            this.generalSuccess = true;
            this.generalError = false;
            this.generalSuccessMessage = 'CONTACTS.ERRORS.SUBMITSUCCESS';
            this.loadVideos();
            this.SwitchCategory(controls['Category'].value);
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {
            this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
            this.generalSuccess = false;
            this.generalError = true;
          }

          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  deleteVideo(id) {
    this.VideoGalleryService.deleteVideos(id)
      .pipe(
        tap(res => {
          if (res) {
            this.loadVideos();
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {
            this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
            this.generalSuccess = false;
            this.generalError = true;
          }

          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  setDefault(id) {
   
  }
  SwitchCategory(id) {

    if (id == '1') {
      this.currentCategory = 1;
      this.activeDirectoryClass = "category-1";
    }
    if (id == '2') {

      this.currentCategory = 2
      this.activeDirectoryClass = "category-2";

    }
    if (id == '3') {
      this.currentCategory = 3;
      this.activeDirectoryClass = "category-3";

    }
    if (id == '4') {
      this.currentCategory = 4;
      this.activeDirectoryClass = "category-4";

    }
  }


  youtubeUrl(url) {

    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
      ? match[2]
      : null;
  }


  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.profileForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }

}
