import {
  Component, OnInit, Injectable, ChangeDetectorRef
} from '@angular/core';
import { PlayerProfileService } from '../services/playerProfile.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PlayerNavComponent } from './playernavigation/playernav.component';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


import {
  SafeResourceUrl,
  DomSanitizer
} from '@angular/platform-browser';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})




export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  rateForm: FormGroup;
  public viewOtherProfileId;
  public profileData;
  public response;
  public generalSuccess;
  public generalError;
  public generalSuccessMessage;
  public generalErrorMessage;
  public FormArray;
  public rating = false;
  public hasBeingRated = false;
  public canBeRated = false;
  public videoResponse1 = [];
  public videoResponse2 = [];
  public videoResponse3 = [];
  public viewContactInfo;
  public favoritePlayer;
  public showRatingModal = false;

  public profileimage = './assets/img/no-user-photo.jpg';
  public profilename = "";
  public bio;
  public credits = 0;
  public starwidth;

  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];
  public date: { year: number, month: number };
  public model: NgbDateStruct;

  public background;
  constructor(
    private fb: FormBuilder,
    private PlayerProfileService: PlayerProfileService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer) {
  }

  ngOnInit() {
   
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];

    this.route.params.subscribe(params => {
      this.viewOtherProfileId = params['id'];
     

      if (this.viewOtherProfileId) {

        this.getUserProfileById(this.viewOtherProfileId);
     
      } else {
        this.getProfileData();
        this.initForm();
      }
    });



    
    
  }
  ratePlayer(id: string) {


    const rateControls = this.rateForm.controls;
    let rating = 1;

    

    let rate1 = rateControls['rate1'].value;
    let rate2 = rateControls['rate2'].value;
    let rate3 = rateControls['rate3'].value;

    if (rate1 && rate2 && rate3) {


      rating = (Number(rate1) + Number(rate2) + Number(rate3)) / 3;
      rating = Math.round((rating + Number.EPSILON) * 100) / 100;
    }



    let data = {
      id: id,
      rating :rating
    }

  
   


    this.PlayerProfileService.ratePlayer(data)
      .pipe(
        tap(res => {
          this.closeModal();
          this.getUserProfileById(id);
        
        })
      ).subscribe();
  }
  getUserProfileById(id: string) {
    this.PlayerProfileService.getPlayerData(id)
      .pipe(
        tap(res => {
          this.profileData = res;

          this.profileData = this.profileData.result;
          this.bio = this.profileData.description;
          this.rating = this.profileData.rating;
          this.hasBeingRated = this.profileData.currentUserRating;
          this.profilename = this.profileData.firstName + " " + this.profileData.lastName;
          this.credits = this.profileData.credits;
          this.canBeRated = this.profileData.hasCredits;
          this.viewContactInfo = this.profileData.currentUserRating;
          this.starwidth = this.profileData.rating * 20;
          this.favoritePlayer = this.profileData.isInFavorites;

          if (this.profileData.hasCredits == false) {
            this.rating = false
          }

          if (this.profileData.images) { 
          for (let image of this.profileData.images) {
            if (image.isMain) {
              this.profileimage = image.url;
            }
            }
          }
          if (this.profileData.videos) { 
          for (let video of this.profileData.videos) {
            if (!video) {
              return
            }
        
            if (video.category == '1') {
              this.videoResponse1.push(video);
            }

            if (video.category == '2') {
              this.videoResponse2.push(video);
            }

            if (video.category == '3') {
              this.videoResponse3.push(video);
            }
            }
          }
          this.initRateForm();


        })
      ).subscribe();
  }
  getProfileData() {

    this.PlayerProfileService.getMyProfile()
      .pipe(
        tap(res => {
        if (res) {
          this.profileData = res;
          /*
          if (this.profileData.result.images.length) {
            this.profileData.result.images.forEach(element => {
              if (element.isMain == true) {
                this.profileimage = element.url;
              }
            });
            
          }
          this.bio = this.profileData.result.description;
          this.profilename = this.profileData.result.firstName + " " + this.profileData.result.lastName;
          */
          this.profileForm.patchValue({
            FirstName: this.profileData.result.firstName ,
            LastName: this.profileData.result.lastName,
            City: this.profileData.result.city,
            Country: this.profileData.result.country,
           
            Height: this.profileData.result.height,
            Weight: this.profileData.result.weight,
            Nationality: this.profileData.result.nationality,
            Description: this.profileData.result.description
          })
          //alert(this.profileData.result.dateOfBirth.slice(0, 10));

          this.profileData = this.profileData.result;

          if (this.profileData.dateOfBirth.toString().length) {


           
            this.profileForm.patchValue({
              DateOfBirth: this.getDataFormat(this.profileData.dateOfBirth.slice(0, 10))
            });
            this.profileData.dateOfBirth = this.profileData.dateOfBirth.slice(0, 10);
          }
      
        
          let positionbox = document.getElementById(this.profileData.position); 
          positionbox.click();

          let footbox = document.getElementById(this.profileData.preferredFoot);
          footbox.click();
        
           
        

          }
          
        },
          catchError(err => {

            this.router.navigateByUrl('/signup');

            return of([]);
          }),
        )).subscribe();


  }

  getDataFormat(dateValue: string) {
    if (dateValue.indexOf('-') != -1) {
      const myArray = dateValue.split("-");
      let dateFormat = {
        year: +myArray[0],
        month: +myArray[1],
        day: +myArray[2]
      }


      
      return dateFormat;
      
    }

  }
  showRating() {

    this.showRatingModal = true;



  }
  closeModal() {
   
    this.showRatingModal = false;
  }
  initRateForm() {
    this.rateForm = this.fb.group({
      rate1: ['', Validators.compose([
        Validators.required
      ]),
      ],
      rate2: ['', Validators.compose([
        Validators.required
      ]),
      ],
      rate3: ['', Validators.compose([
        Validators.required
      ]),
      ],
    });

  }
  initForm() {
    this.profileForm = this.fb.group({
      FirstName: ['', Validators.compose([
        Validators.required
      ]),
      ],
      LastName: ['', Validators.compose([
        Validators.required
      ]),
      ],
      City: ['', Validators.compose([
        Validators.required
      ]),
      ],
      Country: ['', Validators.compose([
        Validators.required
      ]),
      ],
      DateOfBirth: ['', Validators.compose([
        Validators.required
      ]),
      ],
      Nationality: ['', Validators.compose([
        Validators.required
      ]),
      ],
      Height: ['', Validators.compose([
        Validators.required
      ]),
      ],
       Weight: ['', Validators.compose([
          Validators.required
        ]),
      ],
      Description: ['', Validators.compose([
    
      ]),
      ],
      Positions: [''],
      Foot: [''],
      myPositions: new FormArray([]),
    });
  }

  /**
 * Form Submit
 */
  Submit() {

    const controls = this.profileForm.controls;
    let Date = controls['DateOfBirth'].value;




    if (this.profileForm.invalid) {
      
      Object.keys(controls).forEach(controlName => {
        console.log(controls[controlName]);
        controls[controlName].markAsTouched();
      }
      );
      return;
    }

  

    

    if (Date.month && Date.month.toString().length == 1) {
     
      Date.month = "0" + Date.month;
    }
    if (Date.day && Date.day.toString().length == 1) {
     
      Date.day = "0" + Date.day;
    }
 
 
    let profile = {
      firstName: controls['FirstName'].value,
      lastName: controls['LastName'].value,
      dateOfBirth: Date.year + '-' + Date.month + '-' + Date.day,
      country: controls['Country'].value,
      city: controls['City'].value,
      nationality: controls['Nationality'].value,
      height: controls['Height'].value,
      weight: controls['Weight'].value,
      position: controls['Positions'].value,
      preferredFoot: controls['Foot'].value,
      description: controls['Description'].value
    };


    this.PlayerProfileService.setPlayerData(profile)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.generalSuccess = true;
            this.generalError = false;
            this.generalSuccessMessage = 'CONTACTS.ERRORS.SUBMITSUCCESS';
            window.location.reload();
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {
            this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
            this.generalSuccess = false;
            this.generalError = true;
            }
         
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  onCheckChange(event) {
    const formArray: FormArray = this.profileForm.get('myPositions') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }

    console.log(formArray);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.profileForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  favoritePlayerRemove(id:string) {
    this.PlayerProfileService.removeFromFavorites(id).pipe(
      tap(res => {
        if (res) {
     
          this.favoritePlayer = false;
        }
      }),
      finalize(() => {


      })
    )
      .subscribe();
  }
    
  
  favoritePlayerAdd(id:string) {
    this.PlayerProfileService.addToFavorites(id).pipe(
      tap(res => {
        if (res) {
          this.response = res;
          this.favoritePlayer = true;
        }
      }),
      catchError(err => {

        return of([]);
      }),
      finalize(() => {


      })
    )
      .subscribe();
  }
}


@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {


  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      

      if (date[1].length == 1) {
        date[1] = "0" + date[1];
      }
      if (date[0].length == 1) {
        date[0] = "0"+date[0];
      }

      return {
        year: parseInt(date[2], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[0], 10)
             
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.year + this.DELIMITER + date.month + this.DELIMITER + date.day : '';
  }

}
