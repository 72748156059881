import { Component, OnInit } from '@angular/core';
import { PlayerProfileService } from '../../services/playerProfile.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { ScoutsService } from '../../services/scouts.service';
import { VideoGalleryService } from '../../services/videoGallery.service';
@Component({
    selector: 'app-scout-profile',
    templateUrl: './scoutprofile.component.html',
    styleUrls: ['./scoutprofile.component.scss']
})

export class ScoutProfileComponent implements OnInit {
  profileForm: FormGroup;
  public logo;
  public doc;
  public profileData;
  public response;
  public profileimage = './assets/img/no-user-photo.jpg';
  public profilename;
  public generalSuccess;
  public generalError;
  public generalSuccessMessage;
  public generalErrorMessage;
  public FormArray;
  public notificationsCount = 0;
  public activeSection = 'settings';
  //new pending verified
  public scoutstatus = 'new';
  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];
 public verifiedScout = false;
public logopass = false;
  public documentpass = false;

  public notifications;
  public followedPlayers;
  public background;
  constructor(
    private fb: FormBuilder,
    private PlayerProfileService: PlayerProfileService,
    private ScoutsService: ScoutsService,
    private router: Router,
    private VideoGalleryService: VideoGalleryService) { }

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];

    this.initForm();
    this.getProfile();

  }
  getProfile() {
    this.ScoutsService.getMyScoutProfile()
      .pipe(
        tap(res => {
          this.response = res;
          this.response = this.response.result;
          this.profileData = this.response;
          if (this.response.isVerified) {
          
            this.scoutstatus = 'verified';
            this.profilename = this.profileData.firstName + " " + this.profileData.lastName + '(' + this.profileData.nickName + ')';

            if (this.profileData.footballTeamLogo) {
              this.profileimage = this.profileData.footballTeamLogo.url;
            }

            

            this.profileForm.patchValue({
              firstName: this.profileData.firstName,
              lastName: this.profileData.lastName,
              country: this.profileData.country,
              nickName: this.profileData.nickName,
              team: this.profileData.footballTeamName,

            })



            this.ScoutsService.getScoutNotifications()
              .pipe(
                tap(res => {
                  this.notifications = res;
                  this.notifications = this.notifications.result.items;

                }),

                catchError(err => {


                  if (err) {
                    this.notifications = false;
                 
                  }
                  return of([]);
                }),
              ).subscribe();


            this.getFavorites();





          } else if (!this.response.isVerified && this.response.firstName) {
            this.scoutstatus = 'pending';
          } else {
            this.scoutstatus = 'new';
          }
        }),
        /*catchError(err => {
          if (err) {
           
          }
          return of([]);
        }),*/
      ).subscribe();

  }
  getFavorites() {
    this.ScoutsService.getMyFavoritePlayers()
      .pipe(
        tap(res => {
          this.followedPlayers = res;
          this.followedPlayers = this.followedPlayers.result.items;

        }),

        catchError(err => {


          if (err) {
           
          }
          return of([]);
        }),
    ).subscribe();
  }
  initForm() {
    this.profileForm = this.fb.group({
      firstName: ['', Validators.compose([
        Validators.required
      ]),
      ],
      lastName: ['', Validators.compose([
        Validators.required
      ]),
      ],
      country: ['', Validators.compose([
        Validators.required
      ]),
      ],
      nickName: [{ value:'', disabled:true}, Validators.compose([
          Validators.required
        ]),
        ],
        team: [''],
        logo: [''],
        document: [''],

    });


    
  }

  favoritePlayerRemove(id: string) {
    this.PlayerProfileService.removeFromFavorites(id).pipe(
      tap(res => {
        if (res) {

          this.getFavorites();

        }
      }),
      finalize(() => {


      })
    )
      .subscribe();
  }

  checkNickName(field: string) {
    if (this.scoutstatus == 'verified') {
      return false;
    }
    const controls = this.profileForm.controls;

    if (field == 'firstName' && controls['lastName'].value !== "") {
      
        this.profileForm.patchValue({
          nickName: controls['firstName'].value + controls['lastName'].value
        });
      
    }
    if (field == 'lastName' && controls['firstName'].value !== "") {
     
        this.profileForm.patchValue({
          nickName: controls['firstName'].value + controls['lastName'].value
        });
     
    }

    if (controls['nickName'].value)
      this.ScoutsService.getScoutNickname(controls['nickName'].value)
      .pipe(
        tap(res => {
          this.response = res;
          this.response = this.response.result;

          if (this.response.isUnique == true) {
          } else {
            this.profileForm.patchValue({
              nickName: this.response.possibleNicknames[0]
            });
            
          }
        

        })
      ).subscribe();
    
}
  Submit() {
    
    const controls = this.profileForm.controls;

    

    if (this.profileForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }


    
   
    if (controls['document'].value) {
      let extention = controls['document'].value;
      extention = extention.split('.').pop();

      let document = {
        extension: '.' + extention,
        content: this.doc,
        title: 'Document',
        description: 'Scout Document'
      }
      this.ScoutsService.uploadDocument(document)
        .pipe(
          tap(res => {

          })
        ).subscribe();

    }

    if (controls['logo'].value) {
      let extentionlogo = controls['logo'].value;

      extentionlogo = extentionlogo.split('.').pop();
      let logo = {
        extension: '.' + extentionlogo,
        content: this.logo,
        title: 'Logo',
        description: 'Scout Logo',
        type: 'logo'
      }



      this.VideoGalleryService.addLogo(logo)
        .pipe(
          tap(res => {

          })
        ).subscribe();
    }
 

 

    


    this.submitScoutData();
  }

  deleteDocument(id: string) {
    this.ScoutsService.deleteDocument(id)
      .pipe(
        tap(res => {
          this.getProfile();
        })
      ).subscribe();
  }
  submitScoutData() {
    const controls = this.profileForm.controls;

    let team = controls['team'].value;
    if (team == "") {
      team = " ";
    }
    let data = {
      firstName: controls['firstName'].value,
      lastName: controls['lastName'].value,
      footballTeamName: team,
      country: controls['country'].value,
      operatingCountries: ['bulgaria'],
      nickName: controls['nickName'].value,
    }
    this.ScoutsService.registerScout(data)
      .pipe(
        tap(res => {
    
          this.generalSuccess = true;
          this.generalSuccessMessage = 'CONTACTS.ERRORS.SUBMITSUCCESS';

          this.getProfile();
          localStorage.setItem("profileAddress", "/scoutprofile");
        })
    ).subscribe();
  }
  onCheckChange(event) {
    const formArray: FormArray = this.profileForm.get('myPositions') as FormArray;

    /* Selected */
    if (event.target.checked) {
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }

        i++;
      });
    }

    console.log(formArray);
  }

  isControlHasError(controlName: string, validationType: string): boolean {

   

    const control = this.profileForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
  handleUploadDoc(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.doc = reader.result;
      this.doc = this.doc.replace('data:image/jpeg;base64,', '');
      this.doc = this.doc.replace('data:application/pdf;base64,', '');
      this.doc = this.doc.replace('data:application/msword;base64,', '');
      this.doc = this.doc.replace('application/msword,', '');
      this.doc = this.doc.replace('application/vnd.openxmlformats-officedocument.wordprocessingml.document,', '');
      this.doc = this.doc.replace('data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,', '');
      this.doc = this.doc.replace('image/jpg,', '');
      this.doc = this.doc.replace('application/pdf,', '');
      this.doc = this.doc.replace('image/png,', '');
      this.doc = this.doc.replace('application/vnd.ms-excel,', '');
      this.doc = this.doc.replace('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,', '');

    };
  }

  handleUploadLogo(event) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.logo = reader.result;
      this.logo = this.logo.replace('data:image/jpeg;base64,', '');
      this.logo = this.logo.replace('data:application/pdf;base64,', '');
      this.logo = this.logo.replace('data:image/png;base64,', '');
      this.logo = this.logo.replace('application/msword,', '');
      this.logo = this.logo.replace('application/vnd.openxmlformats-officedocument.wordprocessingml.document,', '');
      this.logo = this.logo.replace('image/jpg,', '');
      this.logo = this.logo.replace('application/pdf,', '');
      this.logo = this.logo.replace('image/png,', '');
      this.logo = this.logo.replace('application/vnd.ms-excel,', '');
      this.logo = this.logo.replace('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,', '');

      console.log(this.logo);
    };
  }


  activeSectionChange(section: string) {
    this.activeSection = section;
  }
}


