import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
@Component({
  selector: 'footer-component',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  

  public legalid1 = 'c2459092-44e8-489d-862a-4f165ab6e415';
  public legalid2 = '5d606b0b-9d8d-488f-bb9a-2f461fe57407';

  ngOnInit() {
  }
}
