import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../services/register.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { ConfirmPasswordValidator } from './confirm-password.validator';

import { AuthService, SocialUser } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";


@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})

export class RegisterComponent  implements OnInit {
  loading = false;
  errors: any = [];
  registerForm: FormGroup
  public response;
  public background;
  public generalError;
  public generalErrorMessage;
  public generalSuccess;
  public generalSuccessMessage;

  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'
  ];
  
  constructor(
    private RegisterService: RegisterService,
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    
  }

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
    this.initForm();
  }

  initForm() {
    this.registerForm = this.fb.group({
      Email: ['', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3)
        
      ]),
      ],
      Password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])
      ],
      ConfirmPassword: ['', Validators.compose([
        Validators.required,
        Validators.minLength(8)
      ])
      ]},
      {
      validator: ConfirmPasswordValidator.MatchPassword
    })
      ;
  }
  /**
	 * Form Submit
	 */
  Submit() {

    const controls = this.registerForm.controls;
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }



    let _user = {
      Email: controls['Email'].value,
      Password: controls['Password'].value,
      ConfirmPassword: controls['ConfirmPassword'].value
      };
 
   
    this.RegisterService.register(_user)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.generalSuccess = true;
            this.generalError = false;
            this.generalSuccessMessage = this.response.message;
    
          }
        }),
        catchError(err => {
         

          if (err && err.status == 400) {
            if (err.error.Email) {
              this.generalError = true;
              this.generalErrorMessage = err.error.Email[0];
            } else if (err && err.status == 400 && err.error.Password) {
              this.generalError = true;
              this.generalErrorMessage = err.error.Password[0];
            } else if (err && err.status == 400 && err.error.ConfirmPassword) {
              this.generalError = true;
              this.generalErrorMessage = err.error.ConfirmPassword[0];
            } else {
              this.generalError = true;
              this.generalErrorMessage = err.error.errorsAsString;
            }
          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(data => {

      this.response = data;
      let token = this.response.idToken;

      this.RegisterService.googleLogin(token)
        .pipe(
          tap(res => {
            if (res) {
              this.response = res;

              if (this.response.result.token) {
                localStorage.setItem(environment.authTokenKey, this.response.result.token);
                localStorage.setItem(environment.userId, this.response.result.userId);
                localStorage.setItem(environment.refreshToken, this.response.result.refreshToken);
                localStorage.setItem(environment.tokenExpiration, this.response.result.expirationDate);

                //login
                this.router.navigateByUrl('/profiletype');
              }

            }
          }),
          catchError(err => {
            if (err && err.status == 400) {
              this.generalError = true;
              this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
            }

            return of([]);
          }),
          finalize(() => {


          })
        )
        .subscribe();

    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(data => {

      this.response = data;
      let token = {
        'userId': this.response.id,
        'userToken': this.response.authToken
      }


      this.RegisterService.facebookLogin(token)
        .pipe(
          tap(res => {
            if (res) {
              this.response = res;
              console.log(this.response)
              if (this.response.result.token) {
                localStorage.setItem(environment.authTokenKey, this.response.result.token);
                localStorage.setItem(environment.userId, this.response.result.userId);
                localStorage.setItem(environment.refreshToken, this.response.result.refreshToken);
                localStorage.setItem(environment.tokenExpiration, this.response.result.expirationDate);
                this.router.navigateByUrl('/profile');
              }

            }
          }),
          catchError(err => {
            if (err && err.status == 400) {
              this.generalError = true;
              this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
            }

            return of([]);
          }),
          finalize(() => {


          })
        )
        .subscribe();

    });
  }

  signOut(): void {
    this.authService.signOut();
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
}
