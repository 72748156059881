import { Component, OnInit } from '@angular/core';
import { PaymentPlanService } from '../services/paymentPlan.service';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { PagesService } from '../services/pages.service';
import { Observable, Subject, of } from 'rxjs';;


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  model;
  public background;
  public response;
  public allratings;
  public paymentPlans;
  public allRatingsCount = 2458;
  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];

  constructor(
    private pagesService: PagesService   
  ) {}
  ngOnInit() {

    this.allRatingsCount = this.allRatingsCount + ((Date.now() - 1638685890588)) / 1000000; // hehe
    this.allRatingsCount = Math.floor(this.allRatingsCount);

    let input_group_focus = document.getElementsByClassName('form-control');
    let input_group = document.getElementsByClassName('input-group');
    for (let i = 0; i < input_group.length; i++) {
      input_group[i].children[0].addEventListener('focus', function () {
        input_group[i].classList.add('input-group-focus');
      });
      input_group[i].children[0].addEventListener('blur', function () {
        input_group[i].classList.remove('input-group-focus');
      });
    }




    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];

    this.pagesService.getAllRatings(35)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.allratings = this.response.result.items;
            this.allRatingsCount = this.allRatingsCount + this.response.result.count;

          }
        }),
        catchError(err => {

          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
   
}
