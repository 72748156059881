import { Component, OnInit , Input, OnChanges} from '@angular/core';
import { PlayerProfileService } from '../../services/playerProfile.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
@Component({
    selector: 'player-nav',
    templateUrl: './playernav.component.html',
    styleUrls: ['./playernav.component.scss']
})

export class PlayerNavComponent implements OnInit {
  public profileimage = 'assets/img/no-user-photo.jpg';
  public profilename = "";
  public bio="";
  public response;
  public credits = 0;
  public rating=0;
  public starwidth;
  newBio: string;


  constructor(
    private PlayerProfileService: PlayerProfileService,
    private router: Router) { }
  ngOnInit() {
    this.getProfileData();  
  }
  getProfileData() {

    this.PlayerProfileService.getMyProfile()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;

            if (this.response.result.description) {
              this.bio = this.response.result.description;
            }
           
            this.profilename = this.response.result.firstName + " " + this.response.result.lastName;
            this.credits = this.response.result.credits;
            if (this.response.result.rating) {
              this.rating = this.response.result.rating;
            }
            


          
            if (this.rating == null) {
              this.rating = 0;
              this.starwidth =0;
            } else {
            
              this.starwidth = this.rating * 20;

            }



       


            for (let image of this.response.result.images) {
              if (image.isMain) {
                this.profileimage = image.url;
              }
            }
          }
        })).subscribe();


  }
}
