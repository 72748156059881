import { Component, OnInit } from '@angular/core';
import { PlayerProfileService } from '../../services/playerProfile.service';

import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
@Component({
  selector: 'adminusers',
  templateUrl: './users.component.html',
})
export class AdminUsersComponent {
  public response;
  public users;
  public creditsAdded;
  constructor(private userService: PlayerProfileService) {
  }
  ngOnInit() {
    // this.getUsers();
    this.getAllPlayers();
  }
  getUsers() {
    this.userService.getAllUsers()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.users = this.response.result.items;

          }
        }),
        catchError(err => {
          return of([]);
        }),
      )
      .subscribe();
  }

  getAllPlayers() {
    this.userService.getAllPlayers()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.users = this.response.result.items;
          }
        }),
        catchError(err => {
          return of([]);
        }),
      )
      .subscribe();
   
  }

  giveCredits(id) {
    let player = {
      playerProfileId: id,
      credits: 100
    }

    this.userService.addCredits(player)
      .pipe(
        tap(res => {
          if (res) {
           
            this.getAllPlayers();
      

          }
        }),
        catchError(err => {





          return of([]);
        }),
      )
      .subscribe();
  }

  deleteUser(id) {
    let user = {
      userId : id,
      isDeleted: true
    }

    this.userService.delleteUser(user)
      .pipe(
        tap(res => {
          if (res) {
            this.getUsers();


          }
        }),
        catchError(err => {





          return of([]);
        }),
      )
      .subscribe();
  }
  undeleteUser(id) {
    let user = {
      userId: id,
      isDeleted: false
    }

    this.userService.delleteUser(user)
      .pipe(
        tap(res => {
          if (res) {
            this.getUsers();


          }
        }),
        catchError(err => {





          return of([]);
        }),
      )
      .subscribe();
  }



  
}
