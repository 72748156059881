import { Component, OnInit } from '@angular/core';
import { PagesService } from '../../services/pages.service';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';




@Component({
  selector: 'legalpages',
  templateUrl: './legalpages.component.html',
})
export class AdminLegalPagesComponent {
  public response;
  public pages;
  public editpage = false;
  public pageId;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '400',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],


  sanitize: true,
  toolbarPosition: 'top',
  toolbarHiddenButtons: [
    ['bold', 'italic'],
    ['fontSize']
  ]
};


  pagesForm: FormGroup;
  constructor(private PagesService: PagesService, private fb: FormBuilder) {
  }
  ngOnInit() {
    this.initForm();
    this.PagesService.getPage()
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;

            this.pages = this.response.result;
            
          }
        })
      )
      .subscribe();
  }


  EditPage(id) {
    this.editpage = true;
    this.pageId = id;

   
    this.PagesService.getSinglePage(id)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.pagesForm.patchValue({
              PageContent_bg: this.response.result.contentBg,
              PageContent_en: this.response.result.contentEn
            })

          }
        }),
        catchError(err => {


          if (err && err.status == 400) {

          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }

   
  




  initForm() {
    this.pagesForm = this.fb.group({
      PageContent_en: [''],
      PageContent_bg: [''],

    });
  }
  /**
	 * Form Submit
	 */
  Submit(id) {

    const controls = this.pagesForm.controls;
    /*if (this.pagesForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }*/



    let page = {
      id:id,
      contentEn: controls['PageContent_en'].value,
      contentBg: controls['PageContent_bg'].value
    };


    this.PagesService.savePage(page)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
           

          }
        }),
        catchError(err => {


          if (err && err.status == 400) {
            
          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  }
 


