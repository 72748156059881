// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  apiUrl: 'https://freekick.cc/',
// apiUrl: 'https://localhost:44381/',
  production: false,
  authTokenKey: 'authTokenKey',
  refreshToken: 'refreshToken',
  userId: 'userId',
  tokenExpiration: 'tokenExpiration',
  facebook: {
    ClientId :"687047295341843"
  },
  google: {
    ClientId:"399780623922-6t31n33ffujr1eav3muf56sed2klom8a.apps.googleusercontent.com"
  },
  paypal: {
    ClientId: "AdQ3TCs9CphieiyQ5Jdn3VWjeiYVX1mRk445rIwzPfxIqC104T_W8tAocINttyLmVnxoq2y2RAtTw8jA"
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 *  apiUrl: 'https://localhost:44381/',
 *    apiUrl: 'https://freekick.cc/',
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
