import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';





@Injectable()
export class PaymentPlanService {

  constructor(private http: HttpClient) { }
 
  public  getPlans() {
    const response = this.http.get(environment.apiUrl +'api/PaymentPlan');
    return response;
  }

  public getSinglePlan(id) {
    const response = this.http.get(environment.apiUrl +'api/PaymentPlan/'+id);
    return response;
  }

  public deletePlan(id) {
    const response = this.http.delete(environment.apiUrl +'api/PaymentPlan/' + id);
    return response;
  }

  public savePlan(plan) {
    const response = this.http.post(environment.apiUrl +'api/PaymentPlan/', { name: 'plan', description: 'description', price: +plan.price, tokens: +plan.tokens });
    return response;
  }

  public editPlan(plan) {
    const response = this.http.put(environment.apiUrl +'api/PaymentPlan/'+plan.id, { name: 'plan', description: 'description', price: +plan.price, tokens: +plan.tokens });
    return response;
  }


  public createPaymentOrder(id) {
    return this.http.get(environment.apiUrl + 'api/Payment/createPayPalOrder/' + id + '/PaymentPlan').toPromise();
  }
 
  public capturePayment(id) {
    let response = this.http.post(environment.apiUrl +'api/Payment/capturePayPalPayment/', { paymentId: id });

    return response;
  }


  public saveMembershipPlan(plan) {
    const response = this.http.post(environment.apiUrl + 'api/MembershipPlan/', { name: 'Membership', description: 'description', price: +plan.price, days: +plan.days });
    return response;

  }
  public getMembershipPlans() {
    const response = this.http.get(environment.apiUrl + 'api/MembershipPlan');
    return response;
  }

  public deleteMembershipPlan(id) {
    const response = this.http.delete(environment.apiUrl + 'api/MembershipPlan/' + id);
    return response;


  }
}
