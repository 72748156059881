import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable()
export class PlayerProfileService {

  constructor(private http: HttpClient) { }

  public setPlayerData(player) {

    const response = this.http.post(environment.apiUrl +'api/Profile/upsertPlayerProfile', {
      firstName: player.firstName,
      lastName: player.lastName,
      address: player.address,
      city: player.city,
      description: player.description,
      country: player.country,
      dateOfBirth: player.dateOfBirth,
      height: +player.height,
      weight: +player.weight,
      position: player.position,
      preferredFoot: player.preferredFoot,
      nationality: player.nationality
    });
    return response;
  }

  public getPlayerData(id) {
    const response = this.http.get(environment.apiUrl +'api/Profile/playerProfile/' + id);
    return response;
  }

  public getMyProfile() {
    const response = this.http.get(environment.apiUrl +'api/Profile/myPlayerProfile/');
    return response;
  }

  public ratePlayer(data) {
    const response = this.http.post(environment.apiUrl +'api/Player/ratePlayer', { playerProfileId: data.id, rating: data.rating });
    return response;
  }

  public addToFavorites(id) {
    const response = this.http.post(environment.apiUrl +'api/Player/addToFavorites/' + id, { playerProfileId: id});
    return response;
  }

  public removeFromFavorites(id) {
    const response = this.http.delete(environment.apiUrl +'api/Player/removeFromFavorites/' + id);
    return response;
  }
  public getAllPlayers() {
    const response = this.http.get(environment.apiUrl + 'api/Player/getAllPlayers');
    return response;
    
  }

  public getAllUsers() {
    const response = this.http.get(environment.apiUrl + 'api/Admin/getAllUsers/?PageSize=1000');
    return response;
    
  }


  public addCredits(credits) {
    const response = this.http.post(environment.apiUrl + 'api/Admin/addPlayerCredits/', { playerProfileId: credits.playerProfileId , credits: credits.credits });
    return response;
    
  }

  public delleteUser(user) {
    const response = this.http.put(environment.apiUrl + 'api/Admin/user/delete/' + user.userId + '/' + user.isDeleted, {});
    return response;

  }
 
}
