import { Component, OnInit , Pipe, PipeTransform} from '@angular/core';
import { SearchService } from '../services/search.service';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import {
  SafeResourceUrl,
  DomSanitizer
} from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
})
export class SearchComponent {
  searchForm: FormGroup;
  public background;
  public response;
  public profileData;
  public generalSuccess;
  public generalError;
  public generalSuccessMessage;
  public generalErrorMessage;
  public FormArray;
  public starwidth;
  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];
  constructor(
    private SearchService: SearchService,
    private fb: FormBuilder,
    private sanitizer: DomSanitizer,
  ) {}
  ngOnInit() {

    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
    this.initForm();
  }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
  initForm() {
    this.searchForm = this.fb.group({
      FirstName: [''],
      LastName: [''],
      Age: [''],
      MinWeight: [''],
      MaxWeight: [''],
      MinHeight: [''],
      MaxHeight: [''],
      Country: [''],
      Nationality: [''],
      Positions: [''],
      Foot: ['']
    
    });
  }
  /**
* Form Submit
*/
  Submit() {

    const controls = this.searchForm.controls;


    if (this.searchForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }



    let profile = '?';
    let args = [];
    if (controls['FirstName'].value) {
     args.push('FirstName=' + controls['FirstName'].value);
    }
    if (controls['LastName'].value) {
      args.push('LastName=' + controls['LastName'].value) ;
    }

    if (controls['Age'].value) {
      args.push('Age=' + controls['Age'].value );
    }

    if (controls['MinHeight'].value) {
      args.push('MinHeight=' + controls['MinHeight'].value) ;
    }
    if (controls['MaxHeight'].value) {
      args.push('MaxHeight=' + controls['MaxHeight'].value );
    }
    if (controls['MinWeight'].value) {
      args.push('MinWeight=' + controls['MinWeight'].value) ;
    }
    if (controls['MaxWeight'].value) {
      args.push('MaxWeight=' + controls['MaxWeight'].value) ;
    }
    if (controls['Positions'].value) {
      args.push('Position=' + controls['Position'].value) ;
    }
    if (controls['Nationality'].value) {
      args.push('Nationality=' + controls['Nationality'].value);
    }
    if (controls['Country'].value) {
      args.push('Country=' + controls['Country'].value);
    }

    
    if (args.length > 0) {
      
      args.forEach(element =>
        profile = profile + element + "&"
      );

      profile = profile.slice(0, -1);
   
    }

    


    this.SearchService.serachPlayers(profile)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.response = this.response.result.items;

           

            

            this.generalSuccess = true;
            this.generalError = false;
            this.generalSuccessMessage = 'CONTACTS.ERRORS.SUBMITSUCCESS';
  
          }
        }),
        catchError(err => {


          if (err && err.status == 400) {
            this.generalErrorMessage = 'CONTACTS.ERRORS.GENERAL';
            this.generalSuccess = false;
            this.generalError = true;
          }

          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.searchForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
}
