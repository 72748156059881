import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../services/register.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
@Component({
    selector: 'app-profile',
    templateUrl: './chooseprofile.component.html',
    styleUrls: ['./chooseprofile.component.scss']
})

export class ChooseProfileComponent implements OnInit {
  public response;
  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'

  ];
  public undesidedUser = false;
  public undesidedScout = false;
  public background;
  constructor(
    private RegisterService: RegisterService,
    private router: Router,) {
  }

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
  }
  AreYouSure() {
    this.undesidedUser = true;
  }
  AreYouSureScout() {
    this.undesidedScout = true;
  }
  ChooseProfileType() {
    this.RegisterService.chooseProfileType('Player')
      .pipe(
      tap(res => {
        if (res) {
          this.response = res;
          localStorage.setItem(environment.authTokenKey, this.response.result.token);
          localStorage.setItem(environment.userId, this.response.result.userId);
          localStorage.setItem(environment.refreshToken, this.response.result.refreshToken);
          localStorage.setItem(environment.tokenExpiration, this.response.result.expirationDate);
          localStorage.setItem("profileAddress", "/profile");
          this.router.navigateByUrl('/profile');
        }
      }),
      catchError(err => {
  
        return of([]);
      }),
      finalize(() => {


      })
    )
      .subscribe();

  }
  ChooseProfileTypeScout() {
    this.RegisterService.chooseProfileType('Scout')
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            localStorage.setItem(environment.authTokenKey, this.response.result.token);
            localStorage.setItem(environment.userId, this.response.result.userId);
            localStorage.setItem(environment.refreshToken, this.response.result.refreshToken);
            localStorage.setItem(environment.tokenExpiration, this.response.result.expirationDate);
            localStorage.setItem("profileAddress", "/scoutprofile");
            this.router.navigateByUrl('/scoutprofile');
          }
        }),
        catchError(err => {
          if (err) {
            this.router.navigateByUrl('/scoutprofile');
          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();

  }

}
