import { Component, OnInit } from '@angular/core';
import { last } from 'rxjs/operators';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent {
  public activeSection = "PaymentPlans";

  ngOnInit() {
    let lastActive = localStorage.getItem("category-a");
    if (lastActive) {
      this.activeSection = lastActive;
    }
  }
 
  openPlans() {
    this.activeSection = "PaymentPlans";
    localStorage.setItem("category-a","PaymentPlans");
  }
  openLegalPages() {
    this.activeSection = "LegalPages";
    localStorage.setItem("category-a", "LegalPages");
  }
  openUsers() {
    this.activeSection = "Users";
    localStorage.setItem("category-a", "Users");
  }
  openPendingScouts() {
    this.activeSection = "PendingScouts";
    localStorage.setItem("category-a", "PendingScouts");
  }
}
