import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, mergeMap  } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

	constructor(private router: Router, private authService: AuthService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return this.returnIt(request, next).pipe(
			catchError(err => {
				if (err.status === 401) {
          return this.authService.renewToken(request.url).pipe(
						mergeMap(renew => {
							if (renew) {
								return this.returnIt(request, next);
							} else {
								localStorage.removeItem(environment.authTokenKey);
								localStorage.removeItem(environment.refreshToken);
								this.router.navigateByUrl('/signup');
								return Observable.throw(err);
							}
						})
					);
				} else {
          return this.returnIt(request, next);
				}
			})
		);
	}

	private returnIt(request: HttpRequest<any>, next: HttpHandler, force: boolean = false): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				Authorization: `Bearer ${localStorage.getItem(environment.authTokenKey)}`,
				'Content-Type': 'application/json'
			}
		});

		return next.handle(request);
	}
}
