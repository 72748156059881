import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';




@Injectable()
export class ScoutsService {

  constructor(private http: HttpClient) { }

  public getPendingScouts() {
    const response = this.http.get(environment.apiUrl +'api/PageContent');
    return response;
  }

  public registerScout(data) {
    const response = this.http.post(environment.apiUrl +'api/Profile/upsertScoutProfile', {

      firstName: data.firstName,
      lastName: data.lastName,
      footballTeamName: data.footballTeamName,
      country: data.country,
      operatingCountries: data.operatingCountries,
      nickName: data.nickName

    });
    return response;
  }
  public getScoutNickname(nickname: string) {
    const response = this.http.get(environment.apiUrl +'api/Profile/checkScoutNickname/' + nickname);
    return response;
  }


  public uploadDocument(data) {

    const response = this.http.post(environment.apiUrl +'api/Profile/addScoutProfileDocument', {
      extension: data.extension,
      content: data.content,
      title: data.title,
      description: data.description
    });
    return response;

  }
  public deleteDocument(id: string) {
    const response = this.http.delete(environment.apiUrl + 'api/Profile/deleteProfileDocument/' + id);
    return response;
  }
   

  public getScoutById(id: string) {
    const response = this.http.get(environment.apiUrl +'api/Profile/scoutProfile/' + id);
    return response;
  }


  public getAllScouts(isVerfield: boolean) {
    const response = this.http.get(environment.apiUrl +'api/Scout/getAllScouts?IsVerified=' + isVerfield + '&PageNumber=1&PageSize=100');
    return response;
  }

  public verifyScout(id: string) {
    const response = this.http.post(environment.apiUrl +'api/Admin/verifyScoutProfile/' + id, { id: id });
    return response;
  }

  public getMyScoutProfile() {
    const response = this.http.get(environment.apiUrl +'api/Profile/myScoutProfile');
    return response;

  }

  public getScoutNotifications() {
    const response = this.http.get(environment.apiUrl +'api/ScoutNotification/my/all');
    return response;

  }
  public checkAllNotifications() {
    const response = this.http.post(environment.apiUrl +'api/ScoutNotification/my/checkAll', {});
    return response;
  }


  public getMyFavoritePlayers() {
    const response = this.http.get(environment.apiUrl +'api/Player/getMyFavoritePlayers');
    return response;
  }

}
