import { Component, OnInit } from '@angular/core';
import { ScoutsService } from '../../services/scouts.service';

import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
@Component({
  selector: 'pendingscouts',
  templateUrl: './pendingscouts.component.html',
})
export class AdminPendingScoutsComponent {
  public response;
  public pendingScouts;
  public noPending = false;
  constructor(private ScoutsService: ScoutsService) {
  }
  ngOnInit() {
    this.getNotverifiedScouts();

  }
  getNotverifiedScouts() {
    this.ScoutsService.getAllScouts(false)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;

            this.pendingScouts = this.response.result.items;
          }
        }),
        catchError(err => {


         
            this.noPending = true;
      
          return of([]);
        }),
      )
      .subscribe();
  }



  approveScout(id:string) {
    this.ScoutsService.verifyScout(id)
      .pipe(
        tap(res => {
          if (res) {
          
           this.getNotverifiedScouts();
          }
        }),
        catchError(err => {



          window.location.reload();
          return of([]);
        })
      )
      .subscribe();
  }
      
  
}
