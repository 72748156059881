import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable()
export class SearchService {

  constructor(private http: HttpClient) { }
 
  public serachPlayers(playerdata) {
    const response = this.http.get(environment.apiUrl +'api/Player/getAllPlayers/' + playerdata);
    return response;
  }
 
}
