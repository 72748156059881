import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';





@Injectable()
export class VideoGalleryService {

  constructor(private http: HttpClient) { }


  
  public deleteVideos(id) {
    const resource = this.http.delete(environment.apiUrl +'api/Profile/deletePlayerVideo/'+id);
  return resource;
}


  public addVideos(video) {
    const resource = this.http.post(environment.apiUrl +'api/Profile/addPlayerVideo', {title: video.title, url: video.url, category: +video.category});
    return resource;
  }

  public addPhoto(photo) {
    const resource = this.http.post(environment.apiUrl +'api/Profile/addProfileImage', { content: photo.content, title: photo.title, category: 0 });
    return resource;
  }
  public addLogo(photo) {
    let path = "";
    if (photo.type == "logo") {
      path = '/footballTeamLogo';
    }
    const resource = this.http.post(environment.apiUrl +'api/Profile/upsertSingleTypeImage' + path, { content: photo.content, title: photo.title, category: 0 });
    return resource;
  }
   

  public deletePhoto(id) {
    const resource = this.http.delete(environment.apiUrl + 'api/Profile/deleteProfileImage/'+id);
    return resource;
  }

  public setProfileImage(id) {
    const resource = this.http.post(environment.apiUrl + 'api/Profile/setProfileImageAsBase/'+id,{id:id});
    return resource;
  }
   
}
