import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../services/register.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';

@Component({
  selector: 'app-emailconfigmation',
  templateUrl: './emailconfigmation.component.html',
  styleUrls: ['./emailconfigmation.component.scss']
})

export class EmailConfirmationComponent  implements OnInit {
  public message;
  public shouldDisplayButton = false;
  private response;
  signinForm: FormGroup;
  public background;
  public generalError;
  public generalErrorMessage;
  public generalSuccess;
  public generalSuccessMessage;


  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'
  ];

  constructor(
    private RegisterService: RegisterService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder
  ) {
  }


  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
    this.initForm();
    let response;
    let url_string = window.location.href;
    let url = new URL(url_string);
    let userId = url.searchParams.get("userId");
    let code = url.searchParams.get("code");



    this.RegisterService.confirmEmail(userId, code)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            this.generalSuccess = true;
            this.generalSuccessMessage = this.response.message;
            this.shouldDisplayButton = true;
          }
        }),
        catchError(err => {
 
          if (err && err.status == 400) {
            if (err.error.Email) {
              this.generalError = true;
              this.generalErrorMessage = err.error.Email[0];
            } else {
              this.generalError = true;
              this.generalErrorMessage = err.error.errorsAsString;
            }
          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();

  
  }
  initForm() {
    this.signinForm = this.fb.group({
      Email: ['', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(320)
      ])
      ]
    });
  }
  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
}
