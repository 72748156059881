import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';




@Injectable()
export class RegisterService {

  constructor(private http: HttpClient) { }
 
  public  register(_user) {
    const resource = this.http.post(environment.apiUrl +'api/Account/register', { Email: _user.Email, Password: _user.Password, ConfirmPassword: _user.ConfirmPassword });
    return resource;
  }
  public login(_user) {
    const resource = this.http.post(environment.apiUrl +'api/Account/authenticate', { Email: _user.Email, Password: _user.Password });
    return resource;
  }
  public confirmEmail(userId, code) {
    const resource = this.http.post(environment.apiUrl +'api/Account/confirmEmail', { userId: userId, code: code });
    return resource;
  }
  public forgottenPassword(email) {
    const resource = this.http.post(environment.apiUrl +'api/Account/forgotPassword', { Email: email });
    return resource;
  }
  public resetPassword(reset) {
    const resource = this.http.post(environment.apiUrl +'api/Account/resetPassword', { userId: reset.userId, code: reset.code, password: reset.Password, confirmPassword: reset.ConfirmPassword  });
    return resource;
  }

  public googleLogin(token) {
    const resource = this.http.post(environment.apiUrl +'api/Account/googleAuthenticate', { idToken: token });
    return resource;
  }
  public facebookLogin(fbobject) {
    const resource = this.http.post(environment.apiUrl +'api/Account/facebookAuthenticate', { userId: fbobject.userId, userToken: fbobject.userToken });
    return resource;
  }
  public chooseProfileType(type) {
    const resource = this.http.post(environment.apiUrl +'api/Role/addToRole', { roleType: type });
    return resource;
  }
  


}
