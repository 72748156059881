import { Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { ContactService } from '../services/contact.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize, takeUntil, catchError, tap, take } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
})
export class ContactsComponent {
  public background;
  public response;
  public emailError;
  public nameError;
  public messageError;

  public generalError;
  public generalSuccess;
  public generalErrorMessage;
  public generalSuccessMessage;

  contactForm: FormGroup
  private backgroundPool= [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'
 
]
  constructor(
    private ContactService: ContactService,
    private fb: FormBuilder
  ) {

  }
  @ViewChild('recaptcha', { static: true }) recaptchaElement: ElementRef;

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
    this.initForm();
  }


  initForm() {
     this.contactForm = this.fb.group({
       Name: ['', Validators.compose([
         Validators.required
       ])
      ],
       Email: ['', Validators.compose([
         Validators.required,
         Validators.email,
         Validators.minLength(3)

       ])
      ],
       Message: ['', Validators.compose([
         Validators.required
       ])
       ],
       recaptchaReactive: ['', Validators.compose([
         Validators.required
       ])]
    });
  }

  Submit() {
    const controls = this.contactForm.controls;
    if (this.contactForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }


    let message = {
      Name: controls['Name'].value,
      Email: controls['Email'].value,
      Description: controls['Message'].value,
      recaptureToken: controls['recaptchaReactive'].value
    };

    this.ContactService.sendEmail(message)
      .pipe(
        tap(res => {
          if (res) {
            this.response = res;
            if (this.response.status == 200) {
              this.generalSuccess= true;
              this.generalSuccessMessage = 'CONTACTS.SUCCESS';
            }

          }
        }),
        catchError(err => {
          console.log(err);

          if (err && err.status == 400 ) {
            if (err.error.Email) {
              this.generalError = true;
              this.generalErrorMessage = err.error.Email[0];

            } else if (err.error.Name) {
              this.generalError = true;
              this.generalErrorMessage = err.error.Name[0];

            } else if (err.error.Description) {
              this.generalError = true;
              this.generalErrorMessage = err.error.Description[0];

            } else if (err.error.recaptureToken) {
              this.generalError = true;
              this.generalErrorMessage = err.error.recaptureToken[0];
            }else {
              this.generalError = true;
              this.generalErrorMessage = err.error.errorsAsString;
            }
          }
          if (err && err.status == 401) {
            this.generalError = true;
            this.generalErrorMessage = 'CONTACTS.ERRORS.BADTOKEN';
          }
          return of([]);
        }),
        finalize(() => {


        })
      )
      .subscribe();
  }

  CloseErrorMessage() {
    this.generalError = false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.contactForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }


   
}
