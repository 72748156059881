import { Component, OnInit, ElementRef } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { interval , Observable } from 'rxjs';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  isExpanded = false;
  public viewSearch = false;
  public isLogged =false;
  public profileUrl;
  public menustatus;

  public newprofileurl;
  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  private toggleButton: any;
  private sidebarVisible: boolean;

  constructor(public location: Location, private element: ElementRef) {
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.updateMenu();

    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];

  
    interval(2000).subscribe(x => {

      this.updateMenu();
    });
  }

  updateMenu() {

    this.isLogged = localStorage.getItem('userId') ? true : false;
    this.profileUrl = localStorage.getItem('profileAddress');

    if (this.profileUrl == '/scoutprofile') {
      this.viewSearch = true;
    }

   
    
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];

    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    html.classList.add('nav-open');

    this.sidebarVisible = true;
  };
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };
  sidebarToggle() {
 
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  };

  logout() {
    localStorage.removeItem('authTokenKey');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('profileAddress');
    localStorage.removeItem('userId');
    this.sidebarClose();
    this.updateMenu();
  }

}
