import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../services/register.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, takeUntil,catchError, tap, take } from 'rxjs/operators';
@Component({
  selector: 'app-forgottenpassword',
  templateUrl: './forgottenpassword.component.html',
  styleUrls: ['./forgottenpassword.component.scss']
})
export class ForgotenPasswordComponent implements OnInit {
  signinForm: FormGroup;
  public response;
  public background;
  public generalError;
  public generalErrorMessage;
  public generalSuccess;
  public generalSuccessMessage;


  private backgroundPool = [
    'bg-1', 'bg-2', 'bg-3', 'bg-4', 'bg-5', 'bg-6', 'bg-7', 'bg-8', 'bg-9', 'bg-10', 'bg-11', 'bg-12', 'bg-13', 'bg-14', 'bg-15', 'bg-16', 'bg-17', 'bg-18', 'bg-19', 'bg-20', 'bg-21', 'bg-22', 'bg-23', 'bg-24', 'bg-25'
  ];

   
  constructor(private RegisterService: RegisterService,
    private router: Router,
    private fb: FormBuilder) {
   }

  ngOnInit() {
    this.background = this.backgroundPool[Math.floor(Math.random() * this.backgroundPool.length)];
    this.initForm();
  }

  initForm() {
    this.signinForm = this.fb.group({
      Email: ['', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(3),
        Validators.maxLength(320)
      ])
      ]
    });
  }
  /**
 * Form Submit
 */

  Submit() {
    const controls = this.signinForm.controls;
    if (this.signinForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      return;
    }
    let _email = controls['Email'].value;


    this.RegisterService.forgottenPassword(_email)
    .pipe(
      tap(res => {
        if (res) {
          this.response = res;
          this.generalError = false;
          this.generalSuccess = true;
          this.generalSuccessMessage = this.response.message;
        }
      }),
      catchError(err => {
        this.generalSuccess = false;
        if (err && err.status == 400) {
          if (err.error.Email) {
            this.generalError = true;
            
            this.generalErrorMessage = err.error.Email[0];
          } else {
            this.generalError = true;
            this.generalErrorMessage = err.error.errorsAsString;
          }
        }
        return of([]);
      }),
      finalize(() => {
       
     
      })
    )
      .subscribe();

  }

  CloseErrorMessage() {
    this.generalError =false;
  }
  CloseSuccessMessage() {
    this.generalSuccess = false;
  }
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.signinForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
